import React from "react"

const Logo = () => {
  return (
    <svg viewBox="0 0 346.75 36.27" xmlns="http://www.w3.org/2000/svg">
      <g fill="#fff">
        <path d="m0 35.74v-35.36a2 2 0 0 1 .49-.15c3.35.07 6.71 0 10 .26 3.55.23 5.79 2.66 6 6.24.1 1.6.09 3.2.07 4.8 0 2.79-1.25 4.81-3.68 5.67a26.79 26.79 0 0 1 3.17 3 5.18 5.18 0 0 1 .95 2.74 58.85 58.85 0 0 1 0 6.23c-.17 4-2.58 6.5-6.54 6.67-3.32.15-6.64 0-10 0a2.38 2.38 0 0 1 -.46-.1zm5.6-15.64v10.6a40.37 40.37 0 0 0 4.23-.26 2 2 0 0 0 1.66-2.2c0-1.72 0-3.44 0-5.15a2.94 2.94 0 0 0 -2.82-3c-1.01-.09-2.01.01-3.07.01zm0-14.48v9.27h1.82a7.69 7.69 0 0 0 1 0c1.56-.21 2.5-.89 2.59-2.18a44.07 44.07 0 0 0 0-5.14 1.9 1.9 0 0 0 -1.81-1.93c-1.2-.11-2.37-.02-3.63-.02z" />
        <path d="m307.83.34h4.32c2.59 7.28 5.23 14.66 7.85 22.07h.3v-22h5.06v35.28h-4.48q-3.88-10.63-7.88-21.33l-.31.06v21.3h-4.88z" />
        <path d="m111.5.25c4.38.26 8.73-.64 12.9.93a6.28 6.28 0 0 1 4.37 5.62 63.34 63.34 0 0 1 0 7.16 6.52 6.52 0 0 1 -3.23 5.43l-.78.54c1.7 5.19 3.41 10.42 5.17 15.78h-5.85c-1.47-4.93-2.94-9.83-4.41-14.74h-2.89v14.71h-5.28zm5.36 5.32v10.06h3.8a2.6 2.6 0 0 0 2.71-2.51 38.22 38.22 0 0 0 0-5 2.7 2.7 0 0 0 -2.23-2.5c-1.4-.12-2.8-.05-4.28-.05z" />
        <path d="m225.23 35.71v-35.16c.12-.1.18-.2.24-.2 3.75.1 7.51.05 11.24.35 2.83.22 5.17 3 5.3 5.94s.08 5.67.08 8.51c0 4.64 0 9.28-.05 13.91a6.44 6.44 0 0 1 -6 6.6c-3.55.23-7.11.05-10.81.05zm5.39-5h2.8c2.47 0 3.29-.83 3.3-3.32 0-3.84 0-7.68 0-11.52 0-2.52 0-5 0-7.56 0-1.39-.73-2.53-1.82-2.64a40.34 40.34 0 0 0 -4.26 0z" />
        <path d="m303.51 18c0 3.4.06 6.8 0 10.2-.07 3-1.28 5.59-4.07 7a10.9 10.9 0 0 1 -8.5.46c-3.14-1.07-4.7-3.38-5.12-6.57a17.13 17.13 0 0 1 -.15-2.15q0-8.74 0-17.51a10.37 10.37 0 0 1 1.57-6.15 7 7 0 0 1 4.75-3 12.92 12.92 0 0 1 6.26.3 7 7 0 0 1 5.15 6.51c.24 3.62.05 7.27.05 10.9zm-5.39.25c0-3.12 0-6.24 0-9.36a3.51 3.51 0 1 0 -7 0q-.07 9.3 0 18.6a3.16 3.16 0 0 0 1.88 3.04 3.47 3.47 0 0 0 5.15-3.17c.01-3.04-.03-6.08-.03-9.12z" />
        <path d="m150.87 18v9.84a8.8 8.8 0 0 1 -1.37 4.88 7.21 7.21 0 0 1 -4.86 3.18 10.39 10.39 0 0 1 -8.56-1.43 7.3 7.3 0 0 1 -2.87-5.05 21.56 21.56 0 0 1 -.2-3.11c0-5.48 0-11 0-16.43a17.56 17.56 0 0 1 .3-3.7 7 7 0 0 1 5.11-5.6 11.6 11.6 0 0 1 8.17.38c2.9 1.32 4.16 3.88 4.24 7 .09 3.35 0 6.71 0 10.07zm-5.4.25c0-3.12 0-6.24 0-9.36 0-1.44-.46-2.75-2-3.26-2.39-.79-5 0-5.07 3.35q-.08 9.1 0 18.23a4.78 4.78 0 0 0 .86 2.54 3.44 3.44 0 0 0 3.92.94 3.14 3.14 0 0 0 2.23-3.2c.08-3.09.06-6.17.06-9.25z" />
        <path d="m188.6 35.69c-1.6-4.85-3.2-9.67-4.79-14.5-3.78 4.21-3.34 9.35-3.13 14.51h-5.34v-35.33h5.2v15.79l.37.08 6.63-15.85h5.83a13.69 13.69 0 0 1 -.56 1.53c-1.88 3.91-3.82 7.8-5.64 11.74a3 3 0 0 0 0 2.12c2.42 6.5 4.9 13 7.36 19.45a1.69 1.69 0 0 1 0 .46z" />
        <path d="m99.43 35.72h-5.85l-4.82-14.53c-3.77 4.21-3.34 9.34-3.12 14.5h-5.34v-35.31h5.18v15.78l.39.08 6.62-15.84h5.89c-.38.9-.69 1.69-1.06 2.46-1.75 3.62-3.54 7.22-5.22 10.87a2.87 2.87 0 0 0 0 2c2.42 6.5 4.9 13 7.36 19.44a2.16 2.16 0 0 1 -.03.55z" />
        <path d="m346.68 10.34h-5.23c-.11-1-.16-1.88-.35-2.76a2.7 2.7 0 0 0 -3.1-2.25 2.76 2.76 0 0 0 -3 2.42c-.73 3.75.22 5.56 3.75 7.27 1.5.73 3 1.45 4.45 2.31a6.77 6.77 0 0 1 3.56 6 43.66 43.66 0 0 1 -.44 7.59c-.5 2.76-2.58 4.3-5.26 5a9.94 9.94 0 0 1 -8.65-1.41c-2.24-1.64-2.81-4.05-3-6.63 0-.62 0-1.26 0-2h5.22c.14.94.23 1.88.44 2.8.37 1.66 1.75 2.49 3.67 2.28a2.86 2.86 0 0 0 2.65-2.96c0-.92-.07-1.85 0-2.75.26-2.13-.88-3.39-2.53-4.34-1.21-.69-2.46-1.32-3.72-1.92-4.8-2.31-6.21-5.82-5.68-11.37.29-2.62 1.14-4.85 3.54-6.32a9 9 0 0 1 5.69-1.2 12 12 0 0 1 4.12 1c2.62 1.27 3.6 3.71 3.86 6.47.08.89.01 1.8.01 2.77z" />
        <path d="m38.21 35.69c.65-2.84 1.27-5.55 1.9-8.26 1-4.43 2.1-8.84 3.13-13.27s1.91-8.65 3-12.93c.56-1.92 2.38-.63 3.56-.93.8-.21 1.06.37 1.2 1.1 1.23 5.2 2.48 10.4 3.7 15.6s2.36 10.17 3.55 15.26c.25 1.08.53 2.15.84 3.38h-5.56l-1.3-6.49c-2.26 0-4.45 0-6.63 0-.28 0-.69.52-.77.86-.4 1.83-.72 3.68-1.08 5.63zm7.79-11.58h5.31c-.83-4.33-1.64-8.57-2.46-12.81h-.37z" />
        <path d="m221.87.36v5.13h-10v10h8.76v5.22h-8.73v9.91h9.92v5h-15.28v-35.26z" />
        <path d="m71.46 25h5.26a13.08 13.08 0 0 1 -.43 5.78c-1.52 5.2-7.19 6.35-11.22 4.87-3-1.11-4.42-3.45-4.84-6.43a36.52 36.52 0 0 1 -.19-5c0-4.92 0-9.84 0-14.76a10.83 10.83 0 0 1 1.44-6c1.66-2.66 4.25-3.46 7.18-3.46a11.63 11.63 0 0 1 2.91.52 6.56 6.56 0 0 1 4.75 5.06 48.84 48.84 0 0 1 .68 5.61h-5.7c0-1 .06-1.89 0-2.79-.2-2.2-1.48-3.32-3.49-2.84a3.13 3.13 0 0 0 -1.81 1.6 7.75 7.75 0 0 0 -.5 3q-.06 7.68 0 15.35a17.38 17.38 0 0 0 .39 3.2 2.76 2.76 0 0 0 2.58 2.1 2.7 2.7 0 0 0 2.63-2.11c.2-1.16.24-2.36.36-3.7z" />
        <path d="m171.78 11.21h-5.28c-.12-1.32-.16-2.55-.36-3.76a2.43 2.43 0 0 0 -2.36-2 2.55 2.55 0 0 0 -2.78 1.46 8.82 8.82 0 0 0 -.51 2.58q-.06 8.58 0 17.16a6.26 6.26 0 0 0 .59 2.66 2.46 2.46 0 0 0 2.62 1.51 2.63 2.63 0 0 0 2.45-2.26c.19-1.13.21-2.28.31-3.53h5.15c1 5.25-1.32 10.32-6.52 11a9.53 9.53 0 0 1 -6.14-1 7.69 7.69 0 0 1 -3.74-6 17.91 17.91 0 0 1 -.16-2.64c0-5.68 0-11.36 0-17a11.23 11.23 0 0 1 1.23-5.67c1.51-2.67 4-3.61 6.9-3.78a8 8 0 0 1 7.66 4.12 14.18 14.18 0 0 1 .94 7.15z" />
        <path d="m21.12.28h5.48v30.37h9.56v5.16h-15z" />
        <path d="m266.88 35.73h-5.38v-30.13h-6.05v-5.22h17.32v5.12h-5.89z" />
        <path d="m275.84.4h5.16v35.28h-5.2z" />
        <path d="m251.3 35.69h-5.19v-35.26h5.19z" />
      </g>
    </svg>
  )
}

export default Logo
